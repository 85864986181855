@import "components/ui/__variables";

.project-list-card {
  // display: flex;
  // width: 322px;
  // height: 123px;

  // flex-direction: column;.
  // justify-content: center;

  cursor: pointer;
  position: relative;

  border-radius: 6px;
  margin-bottom: 1rem;

  background: $white-color;
  box-shadow: 0px 2px 2px -1px rgba(27, 35, 44, 0.07);

  &.active {
    border: 1px solid $primary-color;
    h3 {
      color: $primary-color;
    }
  }

  &.active_ {
    border: 1px solid #FF0000;
    h3 {
      color: $primary-color;
    }
  }


  &__container {
    display: flex;
    gap: 20px;
    padding: 16px;
    margin-top: -22px;
    margin-top: -36px;
    align-items: center;
    div.project-icon {
      display: flex;
      width: 43px;
      height: 43px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #e0f1fb;
      svg {
        margin-top: 3px;
      }
    }

    h3 {
      color: $black-color;
      // font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
      word-wrap: break-word;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 60px);
    }

    p {
      // color: #9e9696;
      color: #4f4d4d;
      // font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 0;
      span {
        color: #000;
        // font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }

  div.team-label {
    width: fit-content;
    margin-left: auto;
    span {
      min-width: 60px;
      height: 30px;
      border-radius: 0px 5px 0px 0px;
      background: #e0f1fb;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      // font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding-left: 8px;
      padding-right: 8px;
      // line-height: 150%;
    }
  }


  div.team-label_ {
    width: fit-content;
    margin-left: auto;
    span {
      min-width: 60px;
      height: 30px;
      border-radius: 0px 5px 0px 0px;
      background: #f15f5f;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      // font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding-left: 8px;
      padding-right: 8px;
      // line-height: 150%;
    }
  }

}
